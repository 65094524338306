#errorpage {
    padding: 30px;
    width: 100%;
    height: 100%;
    background-color:rgba(206, 213, 218, .5);
    overflow-x: hidden;
    color: var(--mdc-theme-primary);
    z-index: 4999;
    position: absolute;
}
#errorpage h1 {
    font-family: "Roboto Light", "Helvetica", "Arial", "sans-serif";
    font-weight: 300;
    font-size: 6rem;
    line-height: 1.167;
    letter-spacing: "-0.01562em";}

#errorpage h3 {
    font-family: "Roboto Regular", "Helvetica", "Arial", "sans-serif";
    font-weight: 400;
    font-size: 3rem;
    line-height: 1.167;
    letter-spacing: "0em";
}

.error-subline
{
    font-family: "Roboto Medium", "Helvetica", "Arial", "sans-serif";
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: "0.02857em";
    text-transform: uppercase;
}


.body1{
    font-family: "Roboto Regular", "Helvetica", "Arial", "sans-serif";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: "0.00938em"
}

/*LAYOUT*/
.error-spacing{
    margin-top: -7px;
}

.error-container
{
    display: flex;
    width: 100vw;
    height: auto;
}

.error-col-left
{
    height: 100vh;
}

.error-message-container{
    height: auto;
    max-width: 30vw;
    margin-top: 50%;
    margin-left: 30px;
    position: relative;
    z-index: 1;
}

.error-container ul
{
    list-style-type: disc;
    list-style-position: inside;
    margin-top: 7px;
}

.error-container li
{
    line-height: 1.4375;
    margin-bottom: 7px;
}

.error-image-container {
    height: auto;
}

.error-image-container img{
    position: fixed;
    height: 144vh;
    top:-22%;
    -webkit-transform: translateX(8%);
    transform: translateX(8%);
}

/*MEDIA QUERIES*/
@media only screen and (orientation: landscape) {
    .error-message-container{
       padding-bottom: 30px;
    }
}

@media only screen and (orientation: portrait) {
    .error-message-container{
        margin-left:5%;
    }
}

@media (min-width: 1925px) {

    .error-message-container{
        margin: 25% 0 0 10%;
    }
}

@media (max-width: 1280px) {
    .error-container
    {
        display: grid;
    }

    .error-image-container img{
        -webkit-transform: translateX(40%);
        transform: translateX(40%);
    }

    .error-message-container{
        margin-top: 15%;
    }
}


@media (max-width: 950px) {
    .error-image-container img{
        top:-10%;
    }
}

@media (max-width: 600px) {
    .error-image-container img{
        height: auto;
        top: 57%;
    }

    .error-container
    {
        display: grid;
    }

    .error-message-container{
        max-width: 80vw;
        transform: none;
        margin-top: 120px;
    }
}

@media (max-width: 350px) {
    .error-message-container{
        margin-left: 5%;
    }
}
