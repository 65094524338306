body {
    height:100%;
    overflow:hidden;
    margin:0;
    padding:0;
    font-family:Noto Sans, Helvetica, Arial, sans-serif;
    font-size:16px;
    color: #646464;
    background-color:#fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	--mdc-theme-primary: #646464;
    --mdc-theme-secondary: #00893d;
	--mdc-theme-secondary-translucent: #e5f3eb;
	--mdc-theme-error: #b71c1c;
}

html {
    height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* noto sans 400 */
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../public/css/font/NotoSans-Regular.eot'); /* IE9 Compat Modes */
	src: url('../public/css/font/NotoSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../public/css/font/NotoSans-Regular.woff') format('woff'), /* Modern Browsers */
		 url('../public/css/font/NotoSans-Regular.svg') format('svg'); /* Legacy iOS */
}

/* noto sans 500 */
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 500;
	src: url('../public/css/font/NotoSans-Medium.eot'); /* IE9 Compat Modes */
	src: url('../public/css/font/NotoSans-Medium.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../public/css/font/NotoSans-Medium.woff') format('woff'), /* Modern Browsers */
		 url('../public/css/font/NotoSans-Medium.svg') format('svg'); /* Legacy iOS */
}

/* noto sans 700 */
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../public/css/font/NotoSans-Bold.eot'); /* IE9 Compat Modes */
	src: url('../public/css/font/NotoSans-Bold.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../public/css/font/NotoSans-Bold.woff') format('woff'), /* Modern Browsers */
		 url('../public/css/font/NotoSans-Bold.svg') format('svg'); /* Legacy iOS */
}

#krPanoiFrame {
    -webkit-box-shadow: 0px 0px 38px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 38px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 38px 2px rgba(0,0,0,0.75);
}
