/* loader overlay */
#loader-overlay {
	z-index: 101;
  	position: fixed;
	top: 40%;
  	left: calc(55% - 200px);
  	background-color: rgba(255,255,255,0.8);
    height: 200px;
    width: 200px;
  	display: block;
}
.loader {
	/* variable is set in react component */
	 color: var(--themeSecondaryMain);
}

.loader,
.loader:before,
.loader:after {
	 background: var(--themeSecondaryMain);
}
